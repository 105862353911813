import {
  Module,
  VuexModule,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import { UserAccessModel } from "models";
import router from "@/router";

@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  accessToken: string | null = null;
  authenticated = false;
  expiration: string | null = null;
  username: string | null = null;
  userAccess: UserAccessModel | null = null;

  @Mutation
  setLoggedIn({
    expiration,
    accessToken,
    username,
  }: {
    expiration: string;
    accessToken: string;
    username: string;
  }) {
    this.accessToken = accessToken;
    this.expiration = expiration;
    this.authenticated = true;
    this.username = username;
    localStorage.setItem(
      "ClientFile/login",
      JSON.stringify({
        accessToken: this.accessToken,
        expiration: this.expiration,
        authenticated: true,
        username: username,
      })
    );
  }
  @Mutation
  removeLoggedIn() {
    localStorage.removeItem("ClientFile/login");
    this.accessToken = null;
    this.expiration = null;
    this.authenticated = false;
    this.username = null;
  }
  @Mutation
  logout() {
    localStorage.removeItem("ClientFile/login");
    this.accessToken = null;
    this.expiration = null;
    this.authenticated = false;
    this.username = null;
    this.userAccess = null;
    localStorage.removeItem("ClientFile/entityId");
    localStorage.removeItem("ClientFile/tab");
    router.push({ name: "Auth" });
  }
  @Mutation
  setUserAccess({ userAccess }: { userAccess: UserAccessModel | null }) {
    this.userAccess = userAccess;
    /* localStorage.setItem("ClientFile/userAccess", JSON.stringify(userAccess)); */
  }
  @Mutation
  removeUserAccess() {
    /* localStorage.removeItem("ClientFile/userAccess"); */
    this.userAccess = null;
  }

  get isLoggedIn(): boolean {
    return this.authenticated;
  }

  get getLoggedInUser(): string | null {
    if (this.username != null) {
      return this.username;
    } else {
      const key = localStorage.getItem("ClientFile/login");
      let username = "";
      if (key != null) {
        const parsed = JSON.parse(key);
        username = parsed.username;
      }
      return username;
    }
  }

  get getUserAccess(): UserAccessModel | null {
    /* console.log("test UA:", this.userAccess); */
    /*  if (this.userAccess != null) { */
    return this.userAccess;
    /* } else {
      const key: string | null | undefined = localStorage.getItem(
        "ClientFile/userAccess"
      );
      let userAccess: UserAccessModel | null = null;
       console.log("key: ", key);
      if (key != null && key != undefined) {
        const parsed = JSON.parse(key);
        userAccess = parsed.userAccess;
        this.userAccess = userAccess;
      } else {
        userAccess = null;
        this.userAccess = userAccess;
      }
      console.log("getUA: ", userAccess);
      return userAccess;
    } */
  }
}

export default getModule(User);
