import User from "@/store/modules/user";
import axios, { AxiosInstance } from "axios";
import _Vue from "vue";
//import axios key
//create a plugin
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const axiosAuth: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((restConfig) => {
  const u = User.isLoggedIn;
  if (u == true) {
    // is logged in
    const token = User.accessToken;
    /* console.log("Acces token :", token, u, User); */
    const config = restConfig;
    config.headers.Authorization = "Bearer " + User.accessToken;
    return config;
  }
  return restConfig;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = generateMessageFromError(error);
    console.log("Testing message :", message);
    _Vue.prototype.$notificationCreator.createErrorNotification(message);
    if (axios.isAxiosError(error)) {
      console.log("Axios inter: ", error);
      if (
        (error.response && error.response.status == 401) ||
        message.includes("401")
      ) {
        User.logout();
      }
    }
    return Promise.reject(error);
  }
);

const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    console.log("To json response ", error.toJSON(), error);
    if (error.response) {
      if (error.response.data && error.response.data != "") {
        // for our BE
        if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).message
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).message;
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        console.log("data if else ", error.response);
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        console.log("data else ", error.response);
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        console.error("error non message", error);
      }
    } else if (typeof error === "object") {
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        console.error("Couldnt stringify error", error);
        // message = error;
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$axiosInstance = axiosInstance;
    Vue.prototype.$axiosAuth = axiosAuth;
  },
};
